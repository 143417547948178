import {
	ReportPlatformAuditData,
	ReportPlatformAuditVariables,
	REPORT_PLATFORM_AUDIT,
} from '@/lib/auditApi';
import { useQuery } from '@apollo/client';
import { Modal, Table, TableColumnsType } from 'antd';
import { FC } from 'react';
import dayjs from 'dayjs';
import { ReportPlatform } from '@/interfaces/ReportPlatform';
import FinancialRowAudit from '@/routes/ClientServicesPage/components/AuditModal/FinancialRowAudit';

type Props = {
	platform: ReportPlatform;
	open: boolean;
	setOpen: (newOpen: string) => void;
	lastModified: string;
};

const AuditModal: FC<Props> = ({ platform, open, setOpen }: Props) => {
	const { loading, data } = useQuery<
		ReportPlatformAuditData,
		ReportPlatformAuditVariables
	>(REPORT_PLATFORM_AUDIT, { variables: { platform: platform.id } });

	const handleOk = () => {
		setOpen('none');
	};

	const handleCancel = () => {
		setOpen('none');
	};

	const renderValue = (value: any, record: any) => {
		if (record.updatedField === 'financialRows') {
			return <FinancialRowAudit rows={JSON.parse(value)} />;
		} else if (
			record.updatedField === 'startDate' ||
			record.updatedField === 'endDate'
		) {
			return dayjs(value).format('DD-MM-YYYY');
		} else if (record.updatedField === 'notes') {
			return 'N/A';
		} else {
			return value;
		}
	};

	const columns: TableColumnsType<any> = [
		{
			title: 'User',
			dataIndex: 'user',
			key: 'user',
			width: 90,
			render: (user) => user.name,
			ellipsis: true,
		},
		{
			title: 'Field',
			dataIndex: 'updatedField',
			key: 'updatedField',
			width: 100,
			ellipsis: true,
		},
		{
			title: 'Old',
			dataIndex: 'oldValue',
			key: 'oldValue',
			width: 160,
			render: renderValue,
		},
		{
			title: 'New',
			dataIndex: 'newValue',
			key: 'newValue',
			width: 160,
			render: renderValue,
		},
		{
			title: 'Updated at',
			dataIndex: 'timestamp',
			key: 'timestamp',
			width: 150,
			render: (timestamp) => dayjs(timestamp).format('D MMM YYYY  HH:mm:ss z'),
		},
	];

	return (
		<div>
			<Modal
				width={900}
				styles={{ body: { paddingTop: '20px', maxHeight: 650 } }}
				title="Historical changes"
				open={open}
				onOk={handleOk}
				destroyOnClose={true}
				onCancel={handleCancel}
				keyboard
				footer="">
				<Table
					columns={columns}
					dataSource={data?.audits}
					scroll={{ y: 500 }}
					loading={loading}
					bordered
					pagination={false}
					rowClassName={'text-[12px]'}
					size="small"
				/>
			</Modal>
		</div>
	);
};

export default AuditModal;
