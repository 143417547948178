import { formatMoney } from '@/utils/currency';
import { Table } from 'antd';
type Props = {
	totals: {
		objectiveUSD: number;
		objective: number;
	};
	currency?: string;
};

const ManageTargetsSummary = ({ totals }: Props) => {
	const formatMoneyWithUSD = (amount: number) => {
		return formatMoney(amount).replace('$', 'USD ');
	};

	const summaryColumns = [
		{ key: 'salesperson', render: null, fixed: true },
		{ key: 'country', render: null, fixed: true },
		{ key: 'agency', render: null, fixed: true },
		{
			key: 'objectiveUSD',
			render: (
				<span className="font-medium">
					{/* {formatMoney(totals.totalInvestment)} */}
					{formatMoneyWithUSD(totals.objectiveUSD)}
				</span>
			),
		},
		{
			key: 'objective',
			render:
				totals.objective !== 0 ? formatMoney(totals.objective, 'en-US') : null,
		},
		{ key: 'year', render: null },
		{ key: 'actions', render: null },
	];
	return (
		<Table.Summary.Row className="bg-gray-50">
			{summaryColumns.map((c, index) => {
				if (index === 0) {
					return (
						<Table.Summary.Cell
							key={c.key}
							index={index}
							colSpan={3}
							align="left">
							<p className="my-0 text-right font-medium">Total</p>
						</Table.Summary.Cell>
					);
				} else if (index === 1 || index === 2) {
					return null;
				}
				return (
					<Table.Summary.Cell
						key={c.key}
						index={index}
						colSpan={1}
						align="left">
						{c.render}
					</Table.Summary.Cell>
				);
			})}
		</Table.Summary.Row>
	);
};

export default ManageTargetsSummary;
