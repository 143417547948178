import {
	Checkbox,
	Form,
	Input,
	InputNumber,
	Table,
	TableColumnsType,
	Tooltip,
	Typography,
} from 'antd';
import { FinancialRow } from './ReconciliationPage';
import TextArea from 'antd/es/input/TextArea';
import { useMutation } from '@apollo/client';
import { UPDATE_FINANCIAL_ROW } from '@/lib/ReconciliationApi';
import dayjs from 'dayjs';
import ReconciliationTableSummary from '@/routes/ReconciliationPage/ReconciliationTableSummary';
import { Report } from '@/interfaces/Report';
import {
	UPDATE_PLATFORM_MUTATION,
	UPDATE_REPORT_MUTATION,
	UpdateReportMutation,
	UPDATE_REPORT_PLATFORMS,
	type ReportPlatformUpdateArgs,
} from '@/lib/reportsApi';
import { getParsedValue } from '@/routes/ReconciliationPage/utils';
import CostEditModal from '@/routes/ReconciliationPage/CostEditModal';
import { SearchOutlined } from '@ant-design/icons';
import { searchFilter } from '@/routes/FinancesDataPage/utils';
import { calculateTotal } from '@/lib/utils';

type Props = {
	rows: FinancialRow[];
	refetch: () => Promise<void>;
	loading: boolean;
};

const ReconciliationTable = ({ rows, refetch }: Props) => {
	const [form] = Form.useForm();

	const [updateRowMutation, { loading: updateRowLoading }] = useMutation<
		FinancialRow,
		{ rowID: string; data: Partial<FinancialRow> }
	>(UPDATE_FINANCIAL_ROW);

	const [updateReportMutation, { loading: updateReportLoading }] = useMutation<
		Report,
		UpdateReportMutation
	>(UPDATE_REPORT_MUTATION);

	const [updateReportPlatformMutation, { loading: updatePlatformLoading }] =
		useMutation(UPDATE_PLATFORM_MUTATION);

	const [updateReportPlatforms] = useMutation(UPDATE_REPORT_PLATFORMS);

	const tableColumns: TableColumnsType<FinancialRow> = [
		{
			title: 'Agency',
			dataIndex: 'agency',
			key: 'agency',
			fixed: true,
			width: 200,
			render: (text) => text || 'N/A',
			onFilter: (value, record) => (record.agency || 'N/A') === value,
			sorter: (a, b) => a.agency.localeCompare(b.agency),
		},

		{
			title: 'Client',
			dataIndex: 'client',
			key: 'client',
			fixed: true,
			width: 150,
			render: (text) => text || 'N/A',
			onFilter: (value, record) => (record.client || 'N/A') === value,
			sorter: (a, b) => a.client.localeCompare(b.client),
		},
		{
			title: 'Campaign',
			dataIndex: 'campaign',
			key: 'campaign',
			fixed: true,
			width: 150,
			filterSearch: true,
			filterIcon: <SearchOutlined />,
			filterDropdown: searchFilter,
			render: (text) => text || 'N/A',
			onFilter: (value, record) => {
				const campaignText = String(record.campaign || '').toLowerCase();
				const filterValue = String(value || '').toLowerCase();
				return campaignText.includes(filterValue);
			},
			sorter: (a, b) =>
				String(a.campaign || '').localeCompare(String(b.campaign || '')),
		},
		{
			title: 'Sub Campaign',
			dataIndex: 'platformName',
			key: 'platformName',
			fixed: true,
			width: 180,
			filterSearch: true,
			filterIcon: <SearchOutlined />,
			filterDropdown: searchFilter,
			render: (text, record) => (
				<div>
					<div>{text || 'N/A'}</div>
					<div className="mt-2 text-xs font-light text-gray-400">
						{dayjs(record.subcampaignStartDate).format('DD/MM')} -{' '}
						{dayjs(record.subcampaignEndDate).format('DD/MM')}
					</div>
				</div>
			),
			onFilter: (value, record) => {
				const platformNameText = String(
					record.platformName || ''
				).toLowerCase();
				const filterValue = String(value || '').toLowerCase();
				return platformNameText.includes(filterValue);
			},
			sorter: (a, b) =>
				String(a.platformName || '').localeCompare(
					String(b.platformName || '')
				),
		},
		{
			title: 'Date period',
			dataIndex: 'startDate',
			key: 'startDate',
			fixed: true,
			width: 120,
			render: (_, { startDate, endDate }) => (
				<>
					{dayjs(startDate).format('DD/MM')} - {dayjs(endDate).format('DD/MM')}
				</>
			),
			sorter: (a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)),
		},
		{
			title: 'Order Number',
			dataIndex: 'orderNumber',
			key: 'orderNumber',
			width: 120,
			render: (val, record) => (
				<Form.Item name={`orderNumber-${record.reportId}`} noStyle>
					<Input
						defaultValue={val}
						disabled={updateReportLoading}
						onBlur={(element) => {
							if (element.target.value) {
								updateReport(
									record.reportId,
									'orderNumber',
									element.target.value
								);
							}
						}}
					/>
				</Form.Item>
			),
		},

		{
			title: 'Product',
			dataIndex: 'product',
			key: 'product',
			width: 200,
			render: (products) => (
				<div className="flex items-center">
					<Tooltip title={products.join(', ')}>
						<Typography.Paragraph
							style={{ marginBottom: 0 }}
							ellipsis={{ rows: 1 }}>
							{products.join(', ')}
						</Typography.Paragraph>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Platform',
			dataIndex: 'platform',
			key: 'platform',
			width: 130,
			render: (text) => text || 'N/A',
			// filters: platformFilters,
			onFilter: (value, record) => (record.platform || 'N/A') === value,
			sorter: (a, b) => {
				const platformA = a.platform || 'N/A';
				const platformB = b.platform || 'N/A';
				return platformA.localeCompare(platformB);
			},
		},

		{
			title: 'Formats',
			dataIndex: 'formats',
			key: 'formats',
			width: 120,
			render: (formats) => formats.join(', '),
		},
		{
			title: 'Business Model',
			dataIndex: 'businessModel',
			key: 'businessModel',
			width: 150,
			render: (businessModels) => businessModels.join(', '),
		},
		{
			title: 'Buying Type & Rate',
			dataIndex: 'buyingType',
			key: 'buyingType',
			width: 150,
			render: (buyingType, { rate }) =>
				buyingType
					? `${buyingType ? `${buyingType}` : ''} ${rate ? `($${rate})` : ''}`
					: 'N/A',
		},
		{
			title: (
				<div className="flex gap-x-2">
					<Checkbox
						onChange={(event) =>
							onCheckAll('onTvl', event.target.checked)
						}></Checkbox>
					On TVL
				</div>
			),
			dataIndex: 'onTvl',
			key: 'onTvl',
			width: 100,
			align: 'center',
			render: (val, record) => (
				<Form.Item
					name={`onTvl-${record.platformId}`}
					noStyle
					valuePropName="checked"
					initialValue={val}>
					<Checkbox
						style={{ zIndex: 1 }}
						disabled={updatePlatformLoading}
						onChange={(event) =>
							updateReportPlatform(
								record.platformId,
								'onTvl',
								event.target.checked
							)
						}
					/>
				</Form.Item>
			),
		},
		{
			title: 'Investment',
			dataIndex: 'investment',
			key: 'investment',
			width: 200,
			render: (val, record) => (
				<Form.Item
					name={`investment-${record.platformId}`}
					noStyle
					initialValue={val}>
					<InputNumber
						disabled={updatePlatformLoading}
						onBlur={(element) => {
							const v = element.target.value;

							updateReportPlatform(
								record.platformId,
								'investment',
								getParsedValue(v)
							);
						}}
						style={{ width: '100%' }}
						prefix={record.currency}
					/>
				</Form.Item>
			),
		},

		{
			title: 'Monthly Billing',
			dataIndex: 'monthlyBilling',
			key: 'monthlyBilling',
			width: 200,
			render: (val, record) => (
				<Form.Item
					name={`monthlyBilling-${record.id}`}
					style={{ marginBottom: 0 }}
					initialValue={val}
					rules={[
						{ required: true, message: '' },
						{ type: 'number', min: 0, message: '' },
						{
							type: 'number',
							max: record.investment,
							warningOnly: true,
							message: 'Value higher than investment',
						},
					]}>
					<InputNumber
						disabled={updateRowLoading}
						onBlur={(element) => {
							let v = element.target.value;

							updateFinancialRow(
								record.id,
								'monthlyBilling',
								getParsedValue(v)
							);
						}}
						style={{ width: '100%' }}
						prefix={record.currency}
					/>
				</Form.Item>
			),
		},
		{
			title: 'Cost',
			dataIndex: 'cost',
			key: 'cost',
			width: 200,

			render: (val, record) => (
				<div className="pt-6">
					<Form.Item
						name={`cost-${record.id}`}
						initialValue={val}
						style={{ marginBottom: 0 }}
						rules={[
							{ required: true, message: '' },
							{ type: 'number', min: 0, message: '' },
							{
								type: 'number',
								max: record.investment,
								warningOnly: true,
								message: 'Value higher than investment',
							},
						]}>
						<InputNumber
							disabled={updateRowLoading}
							onBlur={(element) => {
								let v = getParsedValue(element.target.value);
								if (v !== undefined && v !== null && Number.isFinite(v)) {
									updateFinancialRow(record.id, 'cost', v);
								}
							}}
							style={{ width: '100%' }}
							prefix={record.currency}
							onChange={(value) =>
								form.setFieldValue(`cost-${record.id}`, value)
							}
						/>
					</Form.Item>
					<CostEditModal
						form={form}
						rowId={record.id}
						spend={val}
						refetch={refetch}
						currency={record.currency}
					/>
				</div>
			),
		},
		{
			title: (
				<div className="flex gap-x-2">
					<Checkbox
						onChange={(event) =>
							onCheckAll('reconciliation', event.target.checked)
						}></Checkbox>
					Reconciled
				</div>
			),
			dataIndex: 'reconciliation',
			key: 'reconciliation',
			width: 120,
			align: 'center',
			render: (val, record) => (
				<Form.Item
					name={`reconciliation-${record.platformId}`}
					noStyle
					valuePropName="checked"
					initialValue={val}>
					<Checkbox
						style={{ zIndex: 1 }}
						disabled={updatePlatformLoading}
						onChange={(event) =>
							updateReportPlatform(
								record.id,
								'reconciliation',
								event.target.checked
							)
						}
					/>
				</Form.Item>
			),
		},
		{
			title: 'Comment',
			dataIndex: 'comment',
			key: 'comment',
			width: 300,
			render: (val, record) => (
				<Form.Item
					name={`comment-${record.id}`}
					initialValue={val}
					style={{ marginBottom: 0 }}>
					<TextArea
						disabled={updateRowLoading}
						onBlur={(element) => {
							updateFinancialRow(record.id, 'comment', element.target.value);
						}}
						className="mb-0 w-full"
						autoSize={{ minRows: 1, maxRows: 2 }}
						style={{ resize: 'vertical' }}
					/>
				</Form.Item>
			),
		},
	];

	const onCheckAll = (field: string, isChecked: boolean) => {
		const updates = rows.map((row) => ({
			where: { id: row.platformId },
			data: { [field]: isChecked },
		}));

		rows.forEach((row) => {
			form.setFieldValue(`${field}-${row.platformId}`, isChecked);
		});

		updatePlatforms(updates);
	};

	const updatePlatforms = async (data: ReportPlatformUpdateArgs[]) => {
		await updateReportPlatforms({
			variables: {
				data: data,
			},
		});
		await refetch();
	};

	const updateReport = async (reportId: string, field: string, value: any) => {
		await updateReportMutation({
			variables: {
				report: reportId,
				data: { [field]: value },
			},
		});
		await refetch();
	};

	const updateReportPlatform = async (
		platformId: string,
		field: string,
		value: any
	) => {
		await updateReportPlatformMutation({
			variables: {
				id: platformId,
				data: { [field]: value },
			},
		});
		await refetch();
	};

	const updateFinancialRow = async (
		rowId: string,
		field: string,
		value: any
	) => {
		await updateRowMutation({
			variables: {
				rowID: rowId,
				data: { [field]: value },
			},
		});
		await refetch();
	};

	const hasOnlyOneCurrency = (currentData: readonly FinancialRow[]) => {
		if (currentData) {
			const uniqueCurrencies = new Set(currentData.map((t) => t.currency));
			return uniqueCurrencies.size === 1;
		}
		return false;
	};

	return (
		<div className="rounded-lg border border-gray-200 bg-white p-1 shadow">
			<Form form={form}>
				<Table
					size="small"
					loading={updateRowLoading}
					bordered
					pagination={false}
					scroll={{ x: 2500, y: 750 }}
					columns={tableColumns}
					rowKey={(record) => record.id}
					summary={(currentRows) => {
						const uniqueIds = [
							...new Set(currentRows.map((row) => row.id.split('--')[0])),
						];

						const uniqueCampaigns = uniqueIds
							.map((c) => currentRows.find((r) => r.id.includes(c)))
							.filter((c) => c !== undefined) as FinancialRow[];

						const currency = hasOnlyOneCurrency(uniqueCampaigns)
							? uniqueCampaigns[0].currency
							: null;

						return (
							<Table.Summary fixed="bottom">
								<ReconciliationTableSummary
									totals={{
										currency,
										totalInvestmentLocal: currency
											? calculateTotal(
													uniqueCampaigns.map((c: any) => c.investment)
											  )
											: null,
										totalInvestment: calculateTotal(
											uniqueCampaigns.map(
												(c: any) => c.investment * c.exchangeRate
											)
										),
										totalCost: calculateTotal(
											currentRows.map((c) => c.cost * c.exchangeRate)
										),
										totalCostLocal: currency
											? calculateTotal(currentRows.map((c) => c.cost))
											: null,
										totalMonthlyBilling: calculateTotal(
											currentRows.map((c) => c.monthlyBilling * c.exchangeRate)
										),
										totalMonthlyBillingLocal: currency
											? calculateTotal(currentRows.map((c) => c.monthlyBilling))
											: null,
									}}
								/>
							</Table.Summary>
						);
					}}
					dataSource={rows}></Table>
			</Form>
		</div>
	);
};

export default ReconciliationTable;
