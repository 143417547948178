import { UPDATE_PLATFORM_MUTATION } from '@/lib/reportsApi';
import { formatValue, parseValue } from '@/routes/utils';
import { useMutation } from '@apollo/client';
import { Form, InputNumber, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FC, useEffect } from 'react';

type Props = {
	spend: number;
	record: any;
	updateField: string;
	successMessage: string;
	refetch: any;
	prefix?: string;
};

const SpendCell: FC<Props> = ({
	spend,
	record,
	updateField,
	successMessage,
	refetch,
	prefix,
}: Props) => {
	const [updateReportPlatform, { loading, data, error }] = useMutation(
		UPDATE_PLATFORM_MUTATION
	);
	const handleOnBlur = async () => {
		const newSpend = form.getFieldValue(updateField);
		if (newSpend !== undefined && newSpend !== spend) {
			await updateReportPlatform({
				variables: { id: record.id, data: { [updateField]: newSpend } },
			});
			refetch();
		}
	};

	useEffect(() => {
		if (data) {
			message.success(successMessage);
		} else if (error) {
			message.error(error.message);
		}
	}, [data, error, successMessage]);

	const [form] = useForm();

	useEffect(() => {
		Object.keys(record).forEach((k) => {
			if (record[k] !== form.getFieldValue(k)) {
				form.setFieldValue(k, record[k]);
			}
		});
	}, [record]);

	return (
		<Form form={form} className="pr-1">
			<div className="flex h-9 items-center justify-center">
				<Form.Item name={updateField} initialValue={spend} noStyle>
					<InputNumber
						className=" w-full"
						disabled={loading}
						size="middle"
						onBlur={handleOnBlur}
						prefix={prefix}
						formatter={formatValue}
						parser={parseValue}
						onChange={(value) => form.setFieldValue(updateField, value)}
					/>
				</Form.Item>
			</div>
		</Form>
	);
};

export default SpendCell;

// import { UPDATE_PLATFORM_MUTATION } from '@/lib/reportsApi';
// import { useMutation } from '@apollo/client';
// import { Form, InputNumber, message } from 'antd';
// import { useForm } from 'antd/es/form/Form';
// import { FC, useEffect } from 'react';

// type Props = {
// 	spend: number;
// 	record: any;
// 	updateField: string;
// 	successMessage: string;
// 	refetch: any;
// 	prefix?: string;
// };

// const SpendCell: FC<Props> = ({
// 	spend,
// 	record,
// 	updateField,
// 	successMessage,
// 	refetch,
// 	prefix,
// }: Props) => {
// 	const [updateReportPlatform, { loading, data, error }] = useMutation(
// 		UPDATE_PLATFORM_MUTATION
// 	);
// 	const handleOnBlur = async () => {
// 		const newSpend = form.getFieldValue(updateField);
// 		if (newSpend !== undefined && newSpend !== spend) {
// 			await updateReportPlatform({
// 				variables: { id: record.id, data: { [updateField]: newSpend } },
// 			});
// 			refetch();
// 		}
// 	};
// 	useEffect(() => {
// 		if (data) {
// 			message.success(successMessage);
// 		} else if (error) {
// 			message.error(error.message);
// 		}
// 	}, [data, error, successMessage]);
// 	const [form] = useForm();

// 	useEffect(() => {
// 		Object.keys(record).map((k) => {
// 			if (record[k] != form.getFieldValue(k)) {
// 				form.setFieldValue(k, record[k]);
// 			}
// 		});
// 	}, [record]);

// 	return (
// 		<Form form={form} className="pr-1">
// 			<Form.Item name={updateField} initialValue={spend}>
// 				<InputNumber
// 					className="w-full"
// 					disabled={loading}
// 					size="middle"
// 					onBlur={handleOnBlur}
// 					prefix={prefix}
// 					onChange={(value) => form.setFieldValue(updateField, value)}
// 				/>
// 			</Form.Item>
// 		</Form>
// 	);
// };
// export default SpendCell;

// import { UPDATE_PLATFORM_MUTATION } from '@/lib/reportsApi';
// import { useMutation } from '@apollo/client';
// import { Form, InputNumber, message } from 'antd';
// import { useForm } from 'antd/es/form/Form';
// import { FC, useEffect } from 'react';

// type Props = {
// 	spend: number;
// 	record: any;
// 	updateField: string;
// 	successMessage: string;
// 	refetch: any;
// 	prefix?: string;
// };

// const SpendCell: FC<Props> = ({
// 	spend,
// 	record,
// 	updateField,
// 	successMessage,
// 	refetch,
// 	prefix,
// }: Props) => {
// 	const [updateReportPlatform, { loading, data, error }] = useMutation(
// 		UPDATE_PLATFORM_MUTATION
// 	);
// 	const [form] = useForm();

// 	const handleOnBlur = async () => {
// 		const newSpend = form.getFieldValue(updateField);
// 		if (newSpend !== undefined && newSpend !== spend) {
// 			await updateReportPlatform({
// 				variables: { id: record.id, data: { [updateField]: newSpend } },
// 			});
// 			refetch();
// 		}
// 	};

// 	useEffect(() => {
// 		if (data) {
// 			message.success(successMessage);
// 		} else if (error) {
// 			message.error(error.message);
// 		}
// 	}, [data, error, successMessage]);

// 	useEffect(() => {
// 		Object.keys(record).forEach((k) => {
// 			if (record[k] !== form.getFieldValue(k)) {
// 				form.setFieldValue(k, record[k]);
// 			}
// 		});
// 	}, [record, form]);

// 	const formatMoney = (value: number | undefined): string => {
// 		if (value === undefined || value === null || isNaN(value)) {
// 			return '';
// 		}
// 		const numericValue = Number(value);
// 		if (isNaN(numericValue)) {
// 			return '';
// 		}
// 		const formattedValue = numericValue.toLocaleString(undefined, {
// 			minimumFractionDigits: 0,
// 			maximumFractionDigits: 6,
// 		});
// 		return formattedValue;
// 	};

// 	const parseMoney = (value: string | undefined): number => {
// 		if (value === undefined) {
// 			return 0;
// 		}

// 		const cleanedValue = value.replace(/[^0-9.]/g, '');

// 		if (cleanedValue === '' || cleanedValue === '0') {
// 			return 0;
// 		}

// 		if (cleanedValue.startsWith('0') && cleanedValue.length > 1) {
// 			const decimalIndex = cleanedValue.indexOf('.');
// 			if (decimalIndex === -1) {
// 				return parseFloat(cleanedValue) / Math.pow(10, cleanedValue.length - 1);
// 			} else {
// 				return parseFloat(cleanedValue);
// 			}
// 		}

// 		return parseFloat(cleanedValue);
// 	};

// 	return (
// 		<Form form={form} className="pr-1">
// 			<Form.Item
// 				name={updateField}
// 				initialValue={spend}
// 				noStyle
// 				className="flex items-center justify-center">
// 				<InputNumber
// 					className="w-full"
// 					disabled={loading}
// 					size="middle"
// 					onBlur={handleOnBlur}
// 					formatter={formatMoney}
// 					parser={parseMoney}
// 					prefix={prefix}
// 					onChange={(value) => form.setFieldValue(updateField, value)}
// 				/>
// 			</Form.Item>
// 		</Form>
// 	);
// };

// export default SpendCell;
