import { FC, useEffect, useState } from 'react';
import { AppStateProvider } from './context/AppStateContext';
import Main from './Main';
import { AuthStateProvider } from './context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { FiltersProvider } from './context/FiltersContext';
import { ErrorBoundary } from 'react-error-boundary';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import objectSupport from 'dayjs/plugin/objectSupport';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import ErrorFallback from './components/ErrorFallback';
import { ADMIN_URL } from './lib/constants';
import { Spin } from 'antd';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(quarterOfYear);
dayjs.extend(objectSupport);
dayjs.extend(utc);
dayjs.extend(timezone);

const App: FC = () => {
	const [healthCheck, setHealthCheck] = useState('checking');

	useEffect(() => {
		checkHealth();
	}, []);

	const [componentStack, setComponentStack] = useState<string>();

	const errorHandler = (error: Error, info: { componentStack: string }) => {
		setComponentStack(info.componentStack);
	};

	const checkHealth = async () => {
		const res = await fetch(`${ADMIN_URL}/health-check`);
		if (res.status !== 200) {
			setHealthCheck('faulty');
			return;
		}
		const check = await res.json();
		setHealthCheck(check.status);
	};

	if (healthCheck === 'checking') {
		return (
			<div className="flex items-center justify-center">
				<Spin size="large" />
			</div>
		);
	} else if (healthCheck === 'healthy') {
		return (
			<AuthStateProvider>
				<FiltersProvider>
					<AppStateProvider>
						<BrowserRouter>
							<ErrorBoundary
								FallbackComponent={({ error }) => (
									<ErrorFallback
										error={error}
										componentStack={componentStack}
										showBackHome
									/>
								)}
								onError={errorHandler}>
								<Main />
							</ErrorBoundary>
						</BrowserRouter>
					</AppStateProvider>
				</FiltersProvider>
			</AuthStateProvider>
		);
	} else {
		return (
			<h1 className="text-center text-xl">
				<ErrorFallback
					error={new Error('API did not pass health-check.')}
					customMessage="API did not pass health-check. We are working on it."
					componentStack={componentStack}
				/>
			</h1>
		);
	}
};

export default App;
