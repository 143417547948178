import {
	GET_MEDIA_ANALYSIS_PERFORMANCE_REPORT,
	GET_MEDIA_ANALYSIS_ROWS,
	type MediaAnalysisPerformanceReport,
} from '@/lib/MediaAnalysisApi';
import MediaAnalysisTable from './MediaAnalysisTable';
import { useLazyQuery } from '@apollo/client';
import { useContext, useMemo, useState } from 'react';
import MediaAnalysisForm from './MediaAnalysisForm';

import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Empty, Form } from 'antd';
import MediaAnalysisGraphs from '@/routes/MediaAnalysisPage/MediaAnalysisGraphs';

export type MediaAnalysisRow = {
	id: string;
	agency: string;
	country: string;
	billingCompany: string;
	currency: string;
	exchangeRate: number;
	incentive: number;
	objectiveEffectiveCost: number;
	secondaryKPIEffectiveCost: number;
	objectiveType: string;
	cost: number;
	client: string;
	campaign: string;
	subcampaign: string;
	product: string;
	formats: string[];
	businessModel: string[];
	objectiveSpend: number;
	buyingType: string;
	rate: number;
	orderNumber: string;
	onTvl: Boolean;
	investment: number;
	monthlyBilling: number;
	platform: string;
	startDate: string;
	endDate: string;
	market: string[];
	accountManager: string;
};

const MediaAnalysisPage = () => {
	const [getMediaAnalysisRows, { loading, error, data }] = useLazyQuery<{
		getMediaAnalysisRows: MediaAnalysisRow[];
	}>(GET_MEDIA_ANALYSIS_ROWS, { fetchPolicy: 'network-only' });

	const [
		getMediaAnalysisPerformanceReport,
		{ loading: reportLoading, error: reportError, data: reportData },
	] = useLazyQuery<{
		getPerformanceReport: MediaAnalysisPerformanceReport;
	}>(GET_MEDIA_ANALYSIS_PERFORMANCE_REPORT, { fetchPolicy: 'network-only' });

	const [showRate, setShowRate] = useState(false);

	const handleSubmit = () => {
		const formValues = form.getFieldsValue();
		const queryVariables = {
			startMonth:
				formValues.dateRange[0] &&
				formValues.dateRange[0].startOf('month').format('YYYY-MM-DD'),
			endMonth:
				formValues.dateRange[1] &&
				formValues.dateRange[1].startOf('month').format('YYYY-MM-DD'),
			...(formValues.markets && { markets: formValues.markets }),
			...(formValues.clients && { clients: formValues.clients }),
			...(formValues.agencies && { agencies: formValues.agencies }),
			...(formValues.platforms && { platforms: formValues.platforms }),
			...(formValues.formats && { formats: formValues.formats }),
			...(formValues.accountManagers && {
				accountManagers: formValues.accountManagers,
			}),
			...(formValues.buyingTypes && {
				buyingTypes: formValues.buyingTypes,
			}),
			...(formValues.businessModels && {
				businessModels: formValues.businessModels,
			}),
		};
		if (formValues) {
			getMediaAnalysisRows({
				variables: queryVariables,
			});
			getMediaAnalysisPerformanceReport({
				variables: queryVariables,
			});
		}

		setShowRate(formValues.buyingTypes && formValues.buyingTypes.length === 1);
	};

	const { user } = useContext(AuthContext);

	const [form] = Form.useForm();

	return (
		<div>
			<Form form={form} layout="vertical" onFinish={handleSubmit}>
				{user?.permission?.mediaAnalysisDataSeePage === 'yes' ? (
					<>
						<h3 className="mb-1 mt-0 text-xl font-medium">
							Media Analysis page
						</h3>
						<div className="mb-4 flex items-center gap-x-2"></div>
						<MediaAnalysisForm form={form} loading={loading} />
						{(loading || reportLoading) &&
							data?.getMediaAnalysisRows.length === 0 && <p>Loading...</p>}
						{error && <p>{error.message}</p>}
						{reportError && <p>{reportError.message}</p>}
						{data && (
							<MediaAnalysisTable
								showRate={showRate}
								rows={data.getMediaAnalysisRows}
								loading={loading}
							/>
						)}
						{reportData &&
							reportData.getPerformanceReport.campaignCount !== 0 && (
								<MediaAnalysisGraphs
									reportData={reportData.getPerformanceReport}
								/>
							)}

						{reportData && reportData.getPerformanceReport.campaignCount === 0 && (
							<div className="mt-6 flex items-center justify-center rounded bg-white py-12 shadow">
								<Empty description="There are no campaigns that meet the search criteria so no report can be generated" />
							</div>
						)}
					</>
				) : (
					<Navigate to="/" />
				)}
			</Form>
		</div>
	);
};

export default MediaAnalysisPage;
