import { calculateAverage, calculateTotal } from '@/lib/utils';
import type { MediaAnalysisRow } from '@/routes/MediaAnalysisPage/MediaAnalysisPage';
import type { SummaryTotals } from '@/routes/MediaAnalysisPage/TableSummary';

const getProfitColors = (value: number) => {
	if (value < 21) {
		return 'text-red-500';
	} else if (value >= 21 && value < 31) {
		return 'text-orange-500';
	} else if (value >= 31 && value < 51) {
		return 'text-yellow-500';
	}

	return 'text-green-500';
};

const getMarginColors = (value: number) => {
	if (value < 41) {
		return 'text-red-500';
	} else if (value >= 41 && value < 51) {
		return 'text-orange-500';
	} else if (value >= 51 && value < 61) {
		return 'text-yellow-500';
	}

	return 'text-green-500';
};

const calculateProfit = (
	investment: number,
	cost: number,
	incentive: number
) => {
	return (1 - incentive / 100) * investment - cost;
};

const calculateSummaryTotals = (rows: MediaAnalysisRow[]): SummaryTotals => {
	return {
		totalInvestment: calculateTotal(
			rows.map((row) => row.investment * row.exchangeRate)
		),
		totalProfit: calculateTotal(
			rows.map((row) =>
				calculateProfit(
					row.investment * row.exchangeRate,
					row.cost * row.exchangeRate,
					row.incentive
				)
			)
		),
		totalSpend: calculateTotal(
			rows
				.filter((row) => row.rate !== null)
				.map((row) => {
					if (row.objectiveType === 'budget') {
						return row.objectiveSpend * row.exchangeRate;
					} else if (row.objectiveType === 'absolute') {
						if (row.buyingType === 'CPM' || row.buyingType === 'CPR') {
							return (row.objectiveSpend * row.rate * row.exchangeRate) / 1000;
						} else {
							return row.objectiveSpend * row.rate * row.exchangeRate;
						}
					} else {
						return 0;
					}
				})
		),
		averageEffectiveCost: calculateAverage(
			rows.map((row) => row.secondaryKPIEffectiveCost)
		),
		averageMargin:
			calculateAverage(
				rows
					.filter(
						(row) => row.rate !== null && row.secondaryKPIEffectiveCost !== null
					)
					.map(
						({ rate, secondaryKPIEffectiveCost }) =>
							(rate - secondaryKPIEffectiveCost) / rate
					)
			) * 100,
		averageProfit:
			(calculateTotal(
				rows.map((row) =>
					calculateProfit(
						row.investment * row.exchangeRate,
						row.cost * row.exchangeRate,
						row.incentive
					)
				)
			) /
				calculateTotal(rows.map((row) => row.investment * row.exchangeRate))) *
			100,
		averageRate: calculateAverage(
			rows
				.filter((row) => row.rate !== undefined && row.rate !== null)
				.map((row) => row.rate)
		),
		totalCost: calculateTotal(
			rows
				.filter((row) => row.cost !== null && row.cost !== undefined)
				.map((row) => row.cost * row.exchangeRate)
		),
	};
};

export {
	getProfitColors,
	getMarginColors,
	calculateProfit,
	calculateSummaryTotals,
};
