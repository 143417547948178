import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Button,
	DatePicker,
	Divider,
	Form,
	FormInstance,
	Input,
	InputNumber,
	TimeRangePickerProps,
	message,
} from 'antd';
import { FC } from 'react';
import BusinessModelSelect from './BusinessModelSelect';
import BuyingTypeAndRateField from './BuyingTypeAndRateField';
import DevicesSelect from './DevicesSelect';
import ObjectiveTypeSelect from './ObjectiveTypeSelect';
import OtherVariablesSelect from './OtherVariablesSelect';
import PlatformFormatsSelect from './PlatformFormatSelect';
import SecondaryKPIField from './SecondaryKPIField';
import StatusSelect from './StatusSelect';
import MarketSelect from '@/routes/DataPage/FormFields/MarketSelect';

type Props = {
	form: FormInstance<any>;
};

const PlatformsFields: FC<Props> = ({ form }: Props) => {
	console.log('Forminstance:', form);

	const disabledDate: TimeRangePickerProps['disabledDate'] = (current) => {
		return (
			form.getFieldValue('timePeriod') &&
			current &&
			(current.unix() <
				form.getFieldValue('timePeriod')[0].startOf('day').unix() ||
				current.unix() >
					form.getFieldValue('timePeriod')[1].endOf('day').unix())
		);
	};

	const handleCopyCalculatedValue = (fieldName: number) => {
		const calculatedValue = form.getFieldValue([
			'platforms',
			fieldName,
			'calculatedValue',
		]);
		if (calculatedValue !== undefined) {
			form.setFieldsValue({
				platforms: form
					.getFieldValue('platforms')
					.map((platform: any, index: number) =>
						index === fieldName
							? { ...platform, objectiveValue: calculatedValue }
							: platform
					),
			});

			navigator.clipboard
				.writeText(calculatedValue?.toString() || '')
				.then(() => {
					message.success('Applied to Objective!');
				})
				.catch((error) => {
					message.error('Failed to copy!');
					console.error(error);
				});
		}
	};

	return (
		<>
			<Form.List name="platforms">
				{(fields, { add, remove }) => (
					<div>
						{fields.map((field, index) => (
							<div key={index}>
								<Form.Item
									name={[field.name, 'name']}
									label="Name"
									rules={[{ required: true, message: 'Please add a name' }]}>
									<Input placeholder="Name" />
								</Form.Item>
								<Form.Item
									label={
										<span>
											<span style={{ color: 'red' }}>*</span> Platform & format
										</span>
									}>
									<PlatformFormatsSelect fieldName={field.name} />
								</Form.Item>
								<Form.Item
									label="Market"
									rules={[
										{ required: true, message: 'Please select a market' },
									]}
									name={[field.name, 'market']}>
									<MarketSelect placeholder="Market" />
								</Form.Item>
								<Form.Item
									name={[field.name, 'businessModel']}
									label="Business model"
									rules={[
										{ required: true, message: 'Please add a business model' },
									]}>
									<BusinessModelSelect placeholder="Business Model" />
								</Form.Item>
								<Form.Item label="Fee" name={[field.name, 'fee']}>
									<InputNumber min={0} style={{ width: '100%' }} />
								</Form.Item>

								<Form.Item label="Days" name={[field.name, 'platformDays']}>
									<InputNumber style={{ width: '100%' }} min={0} />
								</Form.Item>

								<Form.Item
									label="Active days"
									name={[field.name, 'activeDays']}
									tooltip="Active days must be lower or equal to sub campaign days">
									<InputNumber style={{ width: '100%' }} min={0} />
								</Form.Item>

								<Form.Item
									label="Time period"
									name={[field.name, 'timePeriod']}
									rules={[
										{
											required: true,
											message: 'Please select a valid date range',
										},
									]}>
									<DatePicker.RangePicker
										disabledDate={disabledDate}
										className="w-full"
									/>
								</Form.Item>
								<Form.Item label="Devices" name={[field.name, 'devices']}>
									<DevicesSelect
										placeholder="Devices"
										onChange={(value) =>
											form.setFieldsValue({ devices: value })
										}
									/>
								</Form.Item>
								<Form.Item
									label="Other variables"
									name={[field.name, 'otherVariables']}>
									<OtherVariablesSelect
										placeholder="Other variables"
										onChange={(value) =>
											form.setFieldsValue({ otherVariables: value })
										}
									/>
								</Form.Item>

								<Form.Item
									label="Calculated Objective"
									name={[field.name, 'calculatedValue']}
									initialValue={0}>
									<InputNumber
										disabled
										style={{ width: '100%' }}
										value={
											form.getFieldValue(['platforms', field.name, 'budget']) ||
											0
										}
										addonAfter={
											<Button
												type="default"
												size="small"
												style={{
													backgroundColor: '#f5f5f5',
													color: '#000',
													border: 'none',
													boxShadow: 'none',
												}}
												onClick={() => handleCopyCalculatedValue(field.name)}>
												Apply objective
											</Button>
										}
									/>
								</Form.Item>

								<Form.Item
									{...field}
									name={[field.name, 'objectiveType']}
									label="Objective"
									rules={[
										{
											required: true,
											message: 'Please input a valid objective',
										},
									]}>
									<ObjectiveTypeSelect
										fieldName={[field.name, 'objectiveValue']}
										prefixName={[field.name, 'objectiveType']}
										onChange={(value) => {
											form.setFieldsValue({
												[field.name]: {
													...form.getFieldsValue([field.name]),
													objectiveType: value,
												},
											});
										}}
									/>
								</Form.Item>

								{form.getFieldValue([field.name, 'objectiveType']) ===
									'absolute' && (
									<Form.Item label="Buying Type & rate">
										<BuyingTypeAndRateField
											fieldName={[field.name, 'rate']}
											prefixName={[field.name, 'buyingType']}
										/>
									</Form.Item>
								)}

								<Form.Item label="Secondary KPI">
									<SecondaryKPIField
										prefixName={[field.name, 'secondaryKPIType']}
										fieldName={[field.name, 'secondaryKPIValue']}
									/>
								</Form.Item>

								<Form.Item
									label="Budget"
									initialValue={0}
									name={[field.name, 'budget']}>
									<InputNumber
										addonBefore="$"
										style={{ width: '100%' }}
										min={0}
									/>
								</Form.Item>

								<Form.Item
									initialValue={0}
									{...field}
									label="Investment"
									name={[field.name, 'investment']}
									rules={[
										{
											required: true,
											message: 'Please input a valid investment',
										},
									]}>
									<InputNumber
										addonBefore="$"
										style={{ width: '100%' }}
										min={0}
									/>
								</Form.Item>

								<Form.Item
									label="Status"
									name={[field.name, 'status']}
									initialValue="active">
									<StatusSelect
										onChange={(value) => form.setFieldsValue({ status: value })}
										placeholder="Status"
									/>
								</Form.Item>
								{index === fields.length - 1 && (
									<Button
										className="w-full"
										onClick={() => remove(field.name)}
										danger
										icon={<MinusCircleOutlined />}>
										Remove sub campaign
									</Button>
								)}
								<Divider />
							</div>
						))}
						<Button
							type="dashed"
							onClick={() => add()}
							block
							icon={<PlusOutlined />}>
							Add sub campaign
						</Button>
					</div>
				)}
			</Form.List>
		</>
	);
};

export default PlatformsFields;
