import { Select, Table, TableColumnsType, Tooltip } from 'antd';
import { MediaAnalysisRow } from './MediaAnalysisPage';
import { formatMoney } from '@/utils/currency';
import { capitalize, getFormattedNumber, isOneOf } from '@/lib/utils';
import {
	calculateSummaryTotals,
	getMarginColors,
	getProfitColors,
} from '@/routes/MediaAnalysisPage/utils';
import TableSummary from '@/routes/MediaAnalysisPage/TableSummary';
import { useState } from 'react';
import dayjs from 'dayjs';
import { CalendarOutlined, InfoCircleOutlined } from '@ant-design/icons';

type Props = {
	rows: MediaAnalysisRow[];
	loading: boolean;
	showRate: boolean;
};

const MediaAnalysisTable = ({ rows, showRate }: Props) => {
	const tableColumns: TableColumnsType<MediaAnalysisRow> = [
		{
			title: 'Subcampaign',
			dataIndex: 'subcampaign',
			key: 'subcampaign',
			fixed: true,
			width: 250,
			render: (value, record) => {
				return (
					<div className="flex items-center gap-x-1">
						<Tooltip
							title={`${dayjs(record.startDate).format('DD/MM/YY')} -
										${dayjs(record.endDate).format('DD/MM/YY')}`}>
							<CalendarOutlined
								style={{ fontSize: 12 }}
								className="text-gray-500"
							/>
						</Tooltip>
						<p className="my-0 break-all">{value}</p>
					</div>
				);
			},
			sorter: (a, b) =>
				a.subcampaign !== undefined
					? a.subcampaign?.localeCompare(b.subcampaign)
					: -1,
		},
		{
			title: 'Campaign',
			dataIndex: 'campaign',
			key: 'campaign',
			fixed: true,
			sorter: (a, b) =>
				a.campaign !== null ? a.campaign?.localeCompare(b.campaign) : -1,
		},
		{
			title: 'Account Manager',
			dataIndex: 'accountManager',
			key: 'accountManager',
			render: (value) => value || 'N/A',
			sorter: (a, b) =>
				a.accountManager !== undefined
					? a.accountManager?.localeCompare(b.accountManager)
					: -1,
		},
		{
			title: 'Agency',
			dataIndex: 'agency',
			key: 'agency',
			sorter: (a, b) =>
				a.agency !== null ? a.agency?.localeCompare(b.agency) : -1,
		},
		{
			title: 'Client',
			dataIndex: 'client',
			key: 'client',
			sorter: (a, b) =>
				a.client !== null ? a.client?.localeCompare(b.client) : -1,
		},
		{
			title: 'Market',
			dataIndex: 'market',
			key: 'market',
			render: (market) => market.join(', '),
			sorter: (a, b) => a.market.join(', ')?.localeCompare(b.market.join(', ')),
		},
		{
			title: 'Platform',
			dataIndex: 'platform',
			key: 'platform',
			sorter: (a, b) =>
				a.platform !== null ? a.platform?.localeCompare(b.platform) : -1,
		},
		{
			title: 'Formats',
			dataIndex: 'formats',
			key: 'formats',
			render: (formats) => formats.join(', '),
		},
		{
			title: 'Business Model',
			dataIndex: 'businessModel',
			key: 'businessModel',
			render: (businessModels, record) => (
				<div>
					{businessModels.join(', ')}{' '}
					<Tooltip
						title={`Objective Type: ${capitalize(record.objectiveType)}`}>
						<InfoCircleOutlined
							style={{ fontSize: 12 }}
							className="text-gray-500"
						/>
					</Tooltip>
				</div>
			),
			sorter: (a, b) =>
				a.businessModel !== undefined
					? a.businessModel
							.join(', ')
							?.localeCompare(b.businessModel.join(', '))
					: -1,
		},
		{
			title: 'Buying Type',
			dataIndex: 'buyingType',
			key: 'buyingType',
			sorter: (a, b) =>
				a.buyingType !== undefined
					? a.buyingType?.localeCompare(b.buyingType)
					: -1,
		},
		{
			align: 'right',
			title: 'Rate',
			dataIndex: 'rate',
			key: 'rate',
			render: (rate, record) =>
				rate ? `$${rate * record.exchangeRate}` : 'N/A',
			sorter: (a, b) => a.rate - b.rate,
		},
		{
			align: 'right',
			title: 'Effective Cost',
			dataIndex: 'secondaryKPIEffectiveCost',
			key: 'secondaryKPIEffectiveCost',
			render: (effectiveCost, record) =>
				effectiveCost !== undefined
					? `$${effectiveCost * record.exchangeRate}`
					: 'N/A',
			sorter: (a, b) => a.rate - b.rate,
		},
		{
			align: 'right',
			title: 'Spend',
			dataIndex: 'objectiveSpend',
			key: 'objectiveSpend',
			render: (
				_,
				{ exchangeRate, objectiveType, objectiveSpend, rate, buyingType }
			) => {
				if (objectiveType === 'budget') {
					return formatMoney(objectiveSpend * exchangeRate);
				} else if (objectiveType === 'absolute') {
					if (buyingType === 'CPM' || buyingType === 'CPR') {
						return formatMoney((objectiveSpend * rate * exchangeRate) / 1000);
					} else {
						return formatMoney(objectiveSpend * rate * exchangeRate);
					}
				}
			},
		},
		{
			align: 'right',
			title: 'Investment',
			dataIndex: 'investment',
			key: 'investment',
			sorter: (a, b) =>
				a.investment * a.exchangeRate - b.investment * b.exchangeRate,
			render: (value, { exchangeRate }) =>
				value ? formatMoney(value * exchangeRate) : 'N/A',
		},
		{
			align: 'right',
			title: 'Cost',
			dataIndex: 'cost',
			key: 'cost',
			sorter: (a, b) => a.cost * a.exchangeRate - b.cost * b.exchangeRate,
			render: (value, { exchangeRate }) =>
				value !== undefined ? formatMoney(value * exchangeRate) : 'N/A',
		},
		{
			align: 'right',
			title: 'Profit',
			dataIndex: 'investment',
			key: 'profit',
			sorter: (a, b) => {
				const profitA =
					(1 - a.incentive / 100) * a.investment * a.exchangeRate -
					a.cost * a.exchangeRate;
				const profitB =
					(1 - b.incentive / 100) * b.investment * b.exchangeRate -
					b.cost * b.exchangeRate;
				return profitA - profitB;
			},
			render: (
				value,
				{ investment, exchangeRate, cost, incentive, currency }
			) => {
				if (cost !== undefined && incentive !== undefined) {
					return formatMoney(
						(1 - incentive / 100) *
							(investment * exchangeRate - cost * exchangeRate)
					);
				} else {
					return 'N/A';
				}
			},
		},
		{
			align: 'right',
			title: 'Margin %',
			dataIndex: 'rate',
			sorter: (a, b) => {
				const marginA = (a.rate - a.secondaryKPIEffectiveCost) / a.rate;
				const marginB = (b.rate - b.secondaryKPIEffectiveCost) / b.rate;
				return marginA - marginB;
			},
			key: 'margin',
			render: (_, { rate, secondaryKPIEffectiveCost }) => {
				if (rate !== null && secondaryKPIEffectiveCost !== undefined) {
					const margin = (rate - secondaryKPIEffectiveCost) / rate;
					return (
						<span className={`${getMarginColors(margin * 100)} font-semibold`}>
							{getFormattedNumber(margin * 100)}%
						</span>
					);
				} else {
					return '-';
				}
			},
		},
		{
			align: 'right',
			title: 'Profit %',
			dataIndex: 'investment',
			key: 'profitPercent',
			sorter: (a, b) => {
				const profitA =
					(((1 - a.incentive / 100) * a.investment - a.cost) / a.investment) *
					100;
				const profitB =
					(((1 - b.incentive / 100) * b.investment - b.cost) / b.investment) *
					100;
				return profitA - profitB;
			},
			render: (_, { investment, cost, incentive }) => {
				if (cost !== undefined && incentive !== undefined) {
					const profit =
						(((1 - incentive / 100) * investment - cost) / investment) * 100;
					return (
						<span className={`${getProfitColors(profit)} font-semibold`}>
							{Number.isFinite(profit) ? `${getFormattedNumber(profit)}%` : '-'}
						</span>
					);
				} else {
					return 'N/A';
				}
			},
		},
	];

	const [showColumns, setShowColumns] = useState(tableColumns);

	const handleChangeColumns = (values: any) => {
		if (values.length === 0) {
			setShowColumns(tableColumns);
			return;
		}
		setShowColumns(
			tableColumns?.filter(
				(c) => isOneOf(c.key as string, values) || c.key === 'subcampaign'
			)
		);
	};

	return (
		<>
			<div className="mt-4 mb-1 flex items-center justify-between">
				<p className="my-0 text-xl font-medium">Results ({rows.length})</p>
				<div className="flex w-80 items-center">
					<Select
						className="w-full"
						mode="multiple"
						placeholder="Select Columns"
						onChange={handleChangeColumns}
						allowClear
						maxTagCount="responsive"
						options={tableColumns
							?.filter((c) => c.key !== 'subcampaign')
							.map((c) => ({
								label: c.title,
								value: c.key,
							}))}
					/>
				</div>
			</div>
			<div className="mt-2 rounded-lg border border-gray-200 bg-white p-2 shadow">
				<Table
					size="small"
					virtual
					bordered
					pagination={false}
					scroll={{ x: 2500, y: 500 }}
					columns={showColumns}
					rowKey={(record) => record.id}
					summary={() => (
						<Table.Summary fixed="bottom">
							<TableSummary
								showRate={showRate}
								totals={calculateSummaryTotals(rows)}
								showColumns={showColumns}
							/>
						</Table.Summary>
					)}
					dataSource={rows}
				/>
			</div>
		</>
	);
};

export default MediaAnalysisTable;
