import CustomTooltip from '@/routes/MediaAnalysisPage/graphs/CustomTooltip';
import ReactApexChart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';

type Props = {
	data: {
		name: string;
		value: number;
	}[];
};

const InvestmentByPlatform = ({ data }: Props) => {
	return (
		<ReactApexChart
			options={{
				chart: {
					width: 307,
					type: 'pie',
				},

				colors: [
					'#f87171',
					'#fbbf24',
					'#a3e635',
					'#c084fc',
					'#059669',
					'#22d3ee',
					'#3b82f6',
					'#a78bfa',
					'#be185d',
					'#d9f99d',
					'#3730a3',
				],
				tooltip: {
					enabled: true,
					custom: function ({ series, seriesIndex, w }) {
						return renderToString(
							<CustomTooltip
								isCurrency
								value={series[seriesIndex]}
								title={w.globals.labels[seriesIndex]}
								color={w.globals.colors[seriesIndex]}
							/>
						);
					},
				},
				labels: data.map((d) => d.name),
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: 'bottom',
							},
						},
					},
				],
			}}
			series={data.map((d) => d.value)}
			type="pie"
			height={312}
		/>
	);
};

export default InvestmentByPlatform;
