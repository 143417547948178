import { getFormattedNumber } from '@/lib/utils';
import { formatMoney } from '@/utils/currency';

type Props = {
	value: number;
	title: string;
	isCurrency?: boolean;
	color?: string;
	suffix?: string;
};

const CustomTooltip = ({ value, title, color, isCurrency, suffix }: Props) => {
	return (
		<div className="rounded bg-white px-2 py-1 shadow-sm">
			<p className="my-0 flex items-center text-sm font-medium text-gray-900">
				{color && (
					<span
						style={{ backgroundColor: color }}
						className={`mr-1 inline-block h-2 w-2 rounded-full`}></span>
				)}
				<span>
					{isCurrency ? formatMoney(value) : getFormattedNumber(value, 2)}
					{suffix}
				</span>
			</p>
			<p className="my-0 text-xs font-light text-gray-500">{title}</p>
		</div>
	);
};

export default CustomTooltip;
