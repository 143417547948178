import { Table } from 'antd';

import { getFormattedNumber } from '@/lib/utils';
import { formatMoney } from '@/utils/currency';

export type SummaryTotals = {
	averageRate: number;
	averageEffectiveCost: number;
	totalSpend: number;
	totalInvestment: number;
	totalProfit: number;
	totalCost: number;
	averageMargin: number;
	averageProfit: number;
};

type Props = {
	totals: SummaryTotals;
	showColumns: any[];
	showRate: boolean;
};

const TableSummary = ({ totals, showColumns, showRate }: Props) => {
	const summaryColumns = [
		{ key: 'campaign', render: null, index: 1 },
		{ key: 'accountManager', render: null, index: 2 },
		{ key: 'agency', render: null, index: 3 },
		{ key: 'client', render: null, index: 4 },
		{ key: 'market', render: null, index: 5 },
		{ key: 'platform', render: null, index: 6 },
		{ key: 'formats', render: null, index: 7 },
		{ key: 'businessModel', render: null, index: 8 },
		{ key: 'buyingType', render: null, index: 9 },
		{
			key: 'rate',
			render: (
				<p className="font-medium">
					{showRate ? (
						<span className="font-medium">
							{formatMoney(totals.averageRate)}
						</span>
					) : (
						''
					)}
				</p>
			),
			index: 10,
		},
		{
			key: 'secondaryKPIEffectiveCost',
			render: (
				<p className="font-medium">
					<span className="font-medium">
						{showRate ? formatMoney(totals.averageEffectiveCost) : ''}
					</span>
				</p>
			),
			index: 11,
		},
		{
			key: 'objectiveSpend',
			render: (
				<p className="font-medium">
					<span className="font-medium">{formatMoney(totals.totalSpend)}</span>
				</p>
			),
			index: 12,
		},
		{
			key: 'investment',
			render: (
				<p className="font-medium">
					<span className="font-medium">
						{formatMoney(totals.totalInvestment)}
					</span>
				</p>
			),
			index: 13,
		},
		{
			key: 'cost',
			render: (
				<p className="font-medium">
					<span className="font-medium">{formatMoney(totals.totalCost)}</span>
				</p>
			),
			index: 14,
		},
		{
			key: 'profit',
			render: (
				<p className="font-medium">
					<span className="font-medium">{formatMoney(totals.totalProfit)}</span>
				</p>
			),
			index: 15,
		},

		{
			key: 'margin',
			render: (
				<p className="font-medium">
					<span className="font-medium">
						{getFormattedNumber(totals.averageMargin, 2)}%
					</span>
				</p>
			),
			index: 16,
		},
		{
			key: 'profitPercent',
			render: (
				<p className="font-medium">
					<span className="font-medium">
						{getFormattedNumber(totals.averageProfit, 2)}%
					</span>
				</p>
			),
			index: 17,
		},
	];

	return (
		<Table.Summary.Row>
			<Table.Summary.Cell index={0} colSpan={1} align="right">
				<p className="my-1 text-base font-semibold">Total</p>
			</Table.Summary.Cell>
			{summaryColumns
				.filter(
					(c) =>
						showColumns.findIndex((sc) => sc.key === c.key) !== -1 &&
						c.key !== 'subcampaign'
				)
				.map((c, index) => {
					return (
						<Table.Summary.Cell
							key={index}
							index={index + 1}
							colSpan={1}
							align="right">
							{c.render}
						</Table.Summary.Cell>
					);
				})}
		</Table.Summary.Row>
	);
};

export default TableSummary;
