import { FiltersContext } from '@/context/FiltersContext';
import {
	SalespersonTargetQueryData,
	SalespersonTargetQueryVariables,
	SALESPERSON_TARGET_QUERY,
	Target,
	DELETE_TARGET_MUTATION,
} from '@/lib/targetsApi';
import { formatMoney } from '@/utils/currency';
import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
	Button,
	DatePicker,
	Form,
	Popconfirm,
	Table,
	TableColumnsType,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useContext, useState } from 'react';
import AddTargetModal from './AddTargetModal';
import EditTargetModal from './EditTargetModal';

import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import ManageTargetsSummary from '@/routes/ManageTargetsPage/ManageTargetsSummary';
import { calculateTotal } from '@/lib/utils';

type Props = {};

const ManageTargets = (props: Props) => {
	const [selectedYear, setSelectedYear] = useState<Dayjs>(dayjs());

	const { data, loading, refetch } = useQuery<
		SalespersonTargetQueryData,
		SalespersonTargetQueryVariables
	>(SALESPERSON_TARGET_QUERY, {
		variables: { year: selectedYear.year().toString() },
	});

	const [deleteTarget, { loading: deleteLoading }] = useMutation(
		DELETE_TARGET_MUTATION
	);

	const { countries, agencies, salespeople } = useContext(FiltersContext);

	const handleDeleteTarget = async (id: string) => {
		await deleteTarget({
			variables: { id },
		});
		await refetch();
	};

	const columns: TableColumnsType<Target> = [
		{
			key: 'salesperson',
			dataIndex: 'salesperson',
			title: 'Salesperson',
			render: (salesperson) => (salesperson ? salesperson.name : 'N/A'),
			sortDirections: ['ascend', 'descend'],
			filters: salespeople.map((e) => ({
				text: e.name,
				value: e.name,
			})),
			onFilter: (value, record) => value === record.salesperson.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'country',
			dataIndex: 'country',
			title: 'Country',
			render: (country) => (country ? country.name : 'N/A'),
			sortDirections: ['ascend', 'descend'],
			filters: countries.map((e) => ({
				text: e.name,
				value: e.name,
			})),
			onFilter: (value, record) => value === record.country.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'agency',
			dataIndex: 'agency',
			title: 'Agency',
			render: (agency) => (agency ? agency.name : 'N/A'),
			sortDirections: ['ascend', 'descend'],
			filters: agencies.map((e) => ({
				text: e.name,
				value: e.name,
			})),
			onFilter: (value, record) => value === record.agency.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'targetUSD',
			dataIndex: 'target',
			title: 'Objective USD',
			render: (objective, record) =>
				formatMoney(objective * record.exchangeRate),
			sortDirections: ['ascend', 'descend'],
			sorter: (a, b) => a.target * a.exchangeRate - b.target * b.exchangeRate,
		},
		{
			key: 'target',
			dataIndex: 'target',
			title: 'Objective',
			render: (billing, record) =>
				formatMoney(billing, 'en-US', record.currency),
			sortDirections: ['ascend', 'descend'],
			sorter: (a, b) => a.target - b.target,
		},
		{
			key: 'year',
			dataIndex: 'year',
			title: 'Year',
			sorter: (a, b) => parseInt(a.year) - parseInt(b.year),
		},
		{
			key: 'actions',
			dataIndex: 'gluCampaignID',
			title: 'Actions',
			width: 90,
			align: 'center',
			render: (value, record) => {
				return (
					<div className="flex justify-center gap-x-2">
						<EditTargetModal refetch={refetch} record={record} />
						<Popconfirm
							title="Delete target?"
							description="Are you sure you want to delete this target?"
							okButtonProps={{ loading: deleteLoading }}
							onConfirm={() => handleDeleteTarget(record.id)}>
							<Button icon={<DeleteOutlined />} />
						</Popconfirm>
					</div>
				);
			},
		},
	];

	const handleYearChange = (value: Dayjs) => {
		setSelectedYear(value);
	};

	const hasOnlyOneCurrency = (currentData: readonly Target[]) => {
		if (currentData) {
			const uniqueCurrencies = new Set(currentData.map((t) => t.currency));
			return uniqueCurrencies.size === 1;
		}
		return false;
	};

	const { user } = useContext(AuthContext);

	return (
		<>
			{user?.permission?.manageObjetivesSeePage === 'yes' ? (
				<>
					<div className="my-2 flex justify-between">
						<div className="flex">
							<h1 className="my-0 text-xl">Manage Objectives</h1>
							<Form.Item label="Date range" className="mb-0 ml-2">
								<DatePicker
									picker="year"
									value={
										selectedYear !== undefined
											? dayjs(selectedYear)
											: selectedYear
									}
									onChange={handleYearChange}
								/>
							</Form.Item>
						</div>
						<AddTargetModal refetch={refetch} />
					</div>
					<Table
						bordered
						rowKey="id"
						className="shadow"
						loading={loading}
						columns={columns}
						pagination={false}
						rowClassName={'text-[12px]'}
						size="middle"
						summary={(currentData) =>
							data &&
							data.targets && (
								<Table.Summary fixed="bottom">
									<ManageTargetsSummary
										currency={
											hasOnlyOneCurrency(currentData)
												? data.targets[0].currency
												: undefined
										}
										totals={{
											objectiveUSD: calculateTotal(
												currentData.map((c) => c.target * c.exchangeRate)
											),
											objective: hasOnlyOneCurrency(currentData)
												? calculateTotal(currentData.map((c) => c.target))
												: 0,
										}}
									/>
								</Table.Summary>
							)
						}
						dataSource={data?.targets}></Table>
				</>
			) : (
				<Navigate to="/" />
			)}
		</>
	);
};

export default ManageTargets;
