import { gql } from 'graphql.macro';

const GET_MEDIA_ANALYSIS_ROWS = gql`
	query GET_MEDIA_ANALYSIS_ROWS(
		$startMonth: String!
		$endMonth: String!
		$markets: [ID!]
		$clients: [ID!]
		$agencies: [ID!]
		$formats: [ID!]
		$platforms: [ID!]
		$accountManagers: [ID!]
		$buyingTypes: [String!]
		$businessModels: [String!]
	) {
		getMediaAnalysisRows(
			startMonth: $startMonth
			endMonth: $endMonth
			markets: $markets
			clients: $clients
			agencies: $agencies
			formats: $formats
			accountManagers: $accountManagers
			platforms: $platforms
			buyingTypes: $buyingTypes
			businessModels: $businessModels
		) {
			id
			agency
			currency
			exchangeRate
			accountManager
			cost
			incentive
			objectiveEffectiveCost
			secondaryKPIEffectiveCost
			objectiveType
			buyingType
			businessModel
			investment
			monthlyBilling
			subcampaign
			objectiveSpend
			platform
			rate
			campaign
			formats
			client
			product
			startDate
			endDate
			market
			accountManager
		}
	}
`;

const GET_MEDIA_ANALYSIS_FILTERS = gql`
	query GET_MEDIA_ANALYSIS_FILTERS {
		agencies {
			id
			name
		}
		countries {
			id
			name
		}
		clients {
			id
			name
		}
		formats {
			id
			name
		}
		platforms {
			id
			name
		}
		accountManagers {
			id
			name
		}
	}
`;

export type MediaAnalysisPerformanceReport = {
	campaignCount: number;
	campaignsByMonth: { name: string; value: number }[];
	campaignsByMarket: { name: string; value: number }[];
	campaignsByAgency: { name: string; value: number }[];
	campaignsByPlatform: { name: string; value: number }[];
	campaignsByFormat: { name: string; value: number }[];
	investmentByPlatform: { name: string; value: number }[];
	agencyInvestment: {
		agency: string;
		investment: number;
		cost: number;
		profit: number;
	}[];
	marginByPlatform: {
		name: string;
		marginPercentage: number;
		profitPercentage: number;
	}[];
	marginByMarket: {
		name: string;
		marginPercentage: number;
		profitPercentage: number;
	}[];
	marginByAgency: {
		name: string;
		marginPercentage: number;
		profitPercentage: number;
	}[];
	marginByFormat: {
		name: string;
		marginPercentage: number;
		profitPercentage: number;
	}[];
	buyingTypeAverage: {
		platform: string;
		key: string;
		children: {
			format: string;
			key: string;
			children: {
				buyingType: string;
				key: string;
				rate: number;
				effectiveCost: number;
			}[];
		}[];
	}[];
};

const GET_MEDIA_ANALYSIS_PERFORMANCE_REPORT = gql`
	query GET_MEDIA_ANALYSIS_PERFORMANCE_REPORT(
		$startMonth: String!
		$endMonth: String!
		$markets: [ID!]
		$clients: [ID!]
		$agencies: [ID!]
		$formats: [ID!]
		$platforms: [ID!]
		$accountManagers: [ID!]
		$buyingTypes: [String!]
		$businessModels: [String!]
	) {
		getPerformanceReport(
			startMonth: $startMonth
			endMonth: $endMonth
			markets: $markets
			clients: $clients
			agencies: $agencies
			formats: $formats
			platforms: $platforms
			accountManagers: $accountManagers
			buyingTypes: $buyingTypes
			businessModels: $businessModels
		) {
			campaignCount
			campaignsByMonth {
				name
				value
			}
			campaignsByMarket {
				name
				value
			}
			campaignsByAgency {
				name
				value
			}
			campaignsByPlatform {
				name
				value
			}
			campaignsByFormat {
				name
				value
			}
			investmentByPlatform {
				name
				value
			}
			agencyInvestment {
				agency
				investment
				cost
				profit
			}
			marginByPlatform {
				name
				marginPercentage
				profitPercentage
			}
			marginByMarket {
				name
				marginPercentage
				profitPercentage
			}
			marginByFormat {
				name
				marginPercentage
				profitPercentage
			}
			marginByAgency {
				name
				marginPercentage
				profitPercentage
			}
			buyingTypeAverage
		}
	}
`;

export {
	GET_MEDIA_ANALYSIS_ROWS,
	GET_MEDIA_ANALYSIS_FILTERS,
	GET_MEDIA_ANALYSIS_PERFORMANCE_REPORT,
};
