import { getFormattedNumber } from '@/lib/utils';
import { formatMoney } from '@/utils/currency';
import { Table } from 'antd';
type Props = {
	totals: {
		totalCost: number;
		totalCostLocal: number | null;
		totalMonthlyBillingLocal: number | null;
		totalInvestmentLocal: number | null;
		currency: string | null;
		totalMonthlyBilling: number;
		totalInvestment: number;
	};
};

const ReconciliationTableSummary = ({ totals }: Props) => {
	const formatMoneyWithUSD = (amount: number) => {
		return formatMoney(amount).replace('$', 'USD ');
	};

	const summaryColumns = [
		{ key: 'agency', render: null, fixed: true },
		{ key: 'client', render: null, fixed: true },
		{ key: 'campaign', render: null, fixed: true },
		{ key: 'platformName', render: null, fixed: true },
		{ key: 'datePeriod', render: null },
		{ key: 'ioOrPo', render: null },
		{ key: 'product', render: null },
		{ key: 'platform', render: null },
		{ key: 'formats', render: null },
		{ key: 'businessModel', render: null },
		{ key: 'buyingType', render: null },
		{ key: 'onTvl', render: null },
		{
			key: 'investment',
			render: (
				<div className="font-medium">
					<p className="my-0">{formatMoneyWithUSD(totals.totalInvestment)}</p>
					{totals.currency &&
						totals.currency !== 'USD' &&
						totals.totalInvestmentLocal && (
							<p className="my-0 font-light text-gray-500">
								{totals.currency}{' '}
								{getFormattedNumber(totals.totalInvestmentLocal)}
							</p>
						)}
				</div>
			),
		},
		{
			key: 'monthlyBilling',
			render: (
				<div className="font-medium">
					<p className="my-0">
						{formatMoneyWithUSD(totals.totalMonthlyBilling)}
					</p>
					{totals.currency &&
						totals.currency !== 'USD' &&
						totals.totalMonthlyBillingLocal && (
							<p className="my-0 font-light text-gray-500">
								{totals.currency}{' '}
								{getFormattedNumber(totals.totalMonthlyBillingLocal)}
							</p>
						)}
				</div>
			),
		},
		{
			key: 'Cost',
			render: (
				<div className="font-medium">
					<p className="my-0">{formatMoneyWithUSD(totals.totalCost)}</p>
					{totals.currency &&
						totals.currency !== 'USD' &&
						totals.totalCostLocal && (
							<p className="my-0 font-light text-gray-500">
								{totals.currency} {getFormattedNumber(totals.totalCostLocal)}
							</p>
						)}
				</div>
			),
		},
		{ key: 'comment', render: null },
	];
	return (
		<Table.Summary.Row className="bg-gray-50">
			{summaryColumns.map((c, index) => {
				if (index === 0) {
					return (
						<Table.Summary.Cell
							key={c.key}
							index={index}
							colSpan={4}
							align="left">
							<p className="my-0 text-right font-medium">Total</p>
						</Table.Summary.Cell>
					);
				} else if (index === 1 || index === 2 || index === 3) {
					return null;
				}
				return (
					<Table.Summary.Cell
						key={c.key}
						index={index}
						colSpan={1}
						align="left">
						{c.render}
					</Table.Summary.Cell>
				);
			})}
		</Table.Summary.Row>
	);
};

export default ReconciliationTableSummary;
