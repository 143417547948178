import { gql } from 'graphql.macro';

const GET_FINANCIAL_ROWS = gql`
	query GET_FINANCIAL_ROWS(
		$startMonth: String!
		$endMonth: String!
		$accountManagers: [ID!]
		$clients: [ID!]
		$agencies: [ID!]
		$platforms: [ID!]
	) {
		getFinancialRows(
			startMonth: $startMonth
			endMonth: $endMonth
			accountManagers: $accountManagers
			clients: $clients
			platforms: $platforms
			agencies: $agencies
		) {
			id
			agency
			currency
			exchangeRate
			buyingType
			businessModel
			investment
			cost
			monthlyBilling
			comment
			platform
			rate
			onTvl
			orderNumber
			platformName
			campaign
			formats
			client
			product
			startDate
			endDate
			platformId
			reportId
			subcampaignStartDate
			subcampaignEndDate
			reconciliation
		}
	}
`;

const UPDATE_FINANCIAL_ROW = gql`
	mutation UPDATE_FINANCIAL_ROW($rowID: String!, $data: FinancialRowInput!) {
		updateFinancialRow(rowID: $rowID, data: $data) {
			id
			financialRows
		}
	}
`;

export { GET_FINANCIAL_ROWS, UPDATE_FINANCIAL_ROW };
