import type { MediaAnalysisPerformanceReport } from '@/lib/MediaAnalysisApi';
import AgencyInvestment from '@/routes/MediaAnalysisPage/graphs/AgencyInvestment';
import BuyingTypeAverageTable from '@/routes/MediaAnalysisPage/graphs/BuyingTypeAverageTable';
import CampaignsByAgency from '@/routes/MediaAnalysisPage/graphs/CampaignsByAgency';
import CampaignsByMarket from '@/routes/MediaAnalysisPage/graphs/CampaignsByMarket';
import CampaignsByMonth from '@/routes/MediaAnalysisPage/graphs/CampaignsByMonth';
import CampaignsByPlatform from '@/routes/MediaAnalysisPage/graphs/CampaignsByPlatform';
import InvestmentByPlatform from '@/routes/MediaAnalysisPage/graphs/InvestmentByPlatform';
import MarginByGraph from '@/routes/MediaAnalysisPage/graphs/MarginByGraph';
import { Card, Col, Row } from 'antd';

type Props = {
	reportData: MediaAnalysisPerformanceReport;
};

const MediaAnalysisGraphs = ({ reportData }: Props) => {
	return (
		<div className="mt-4">
			<Row gutter={16}>
				<Col span={8}>
					<Card size="small" title="Subcampaigns by Month">
						<CampaignsByMonth data={reportData.campaignsByMonth} />
					</Card>
				</Col>
				<Col span={8}>
					<Card size="small" title="Subcampaigns by Market">
						<CampaignsByMarket data={reportData.campaignsByMarket} />
					</Card>
				</Col>
				<Col span={8}>
					<Card size="small" title="Subcampaigns by Agency">
						<CampaignsByAgency data={reportData.campaignsByAgency} />
					</Card>
				</Col>
			</Row>
			<Row gutter={16} className="mt-4">
				<Col span={12}>
					<Card size="small" title="Subcampaigns by Platform">
						<CampaignsByPlatform data={reportData.campaignsByPlatform} />
					</Card>
				</Col>
				<Col span={12}>
					<Card size="small" title="Subcampaigns by Format">
						<CampaignsByPlatform data={reportData.campaignsByFormat} />
					</Card>
				</Col>
			</Row>
			<Row gutter={16} className="mt-4">
				<Col span={16}>
					<Card size="small" title="Investment, Cost and Profit by Agency">
						<AgencyInvestment data={reportData.agencyInvestment} />
					</Card>
				</Col>
				<Col span={8}>
					<Card size="small" title="Investment by Platform">
						<InvestmentByPlatform data={reportData.investmentByPlatform} />
					</Card>
				</Col>
			</Row>
			<Row gutter={16} className="mt-4">
				<Col span={12}>
					<Card size="small" title="Margin and Profit % by Market">
						<MarginByGraph data={reportData.marginByMarket} />
					</Card>
				</Col>
				<Col span={12}>
					<Card size="small" title="Margin and Profit % by Agency">
						<MarginByGraph data={reportData.marginByAgency} />
					</Card>
				</Col>
			</Row>
			<Row gutter={16} className="mt-4">
				<Col span={12}>
					<Card size="small" title="Margin and Profit % by Platform">
						<MarginByGraph data={reportData.marginByPlatform} />
					</Card>
				</Col>
				<Col span={12}>
					<Card size="small" title="Margin and Profit % by Format">
						<MarginByGraph data={reportData.marginByFormat} />
					</Card>
				</Col>
			</Row>
			<Row gutter={16} className="mt-4">
				<Col span={24}>
					<Card size="small" title="Cost and Effective Cost">
						<BuyingTypeAverageTable data={reportData.buyingTypeAverage} />
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default MediaAnalysisGraphs;
