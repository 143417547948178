type Props = {
	rows: {
		startDate: string;
		endDate: string;
		id: string;
		cost: number;
		monthlyBilling: number;
		comment: string;
	}[];
};

const FinancialRowAudit = ({ rows }: Props) => {
	return (
		<div className="max-h-72 overflow-y-auto">
			{rows.map((row) => (
				<div key={row.id} className="my-2 rounded bg-gray-100 px-1 py-2 shadow">
					<code className="text-xs">
						Start Date: {row.startDate}
						<br />
						End Date: {row.endDate}
						<br />
						Cost: {row.cost}
						<br />
						Monthly Billing: {row.monthlyBilling}
						<br />
						Comment: {row.comment}
					</code>
				</div>
			))}
		</div>
	);
};

export default FinancialRowAudit;
