import { FiltersContext } from '@/context/FiltersContext';
import { useQuery } from '@apollo/client';
import { Form, Input, Select } from 'antd';
import { gql } from 'graphql.macro';
import { FC, useContext, useState, useEffect } from 'react';

type Props = {
	fieldName: number | string;
	initialValue?: any;
};

interface FormatsData {
	formats: {
		id?: string;
		name: string;
	}[];
}

const FORMATS_QUERY = gql`
	query ($id: ID!) {
		formats(where: { platform: { some: { id: { equals: $id } } } }) {
			id
			name
			platform {
				id
				name
			}
		}
	}
`;

const PlatformFormatsSelect: FC<Props> = ({
	initialValue,
	fieldName,
}: Props) => {
	const { platforms } = useContext(FiltersContext);
	const [selectedPlatform, setSelectedPlatform] = useState(
		initialValue?.platform ? initialValue.platform.id : ''
	);
	const [formats, setFormats] = useState<string[]>(
		initialValue?.formats?.map((f: any) => f.id) || []
	);

	const { data, loading } = useQuery<FormatsData, { id: string }>(
		FORMATS_QUERY,
		{
			variables: { id: selectedPlatform },
			skip: !selectedPlatform,
		}
	);

	useEffect(() => {
		if (data) {
			setFormats(data.formats.map((format: any) => format.id));
		} else {
			setFormats([]);
		}
	}, [data]);

	const handlePlatformChange = (value: string) => {
		setSelectedPlatform(value);
		setFormats([]);
	};

	return (
		// <Input.Group compact style={{ display: 'flex' }}>
		<Input.Group>
			<Form.Item
				name={[fieldName, 'platform']}
				noStyle
				initialValue={initialValue?.platform?.id}
				rules={[
					{
						required: true,
						message: 'Please select a valid platform',
					},
				]}>
				<Select
					placeholder="Platform"
					showSearch
					loading={loading}
					optionFilterProp="children"
					onChange={handlePlatformChange}
					filterOption={(input, option) =>
						(option!.children as unknown as string)
							.toLowerCase()
							.includes(input.toLowerCase())
					}
					style={{ width: '49%', marginRight: '1%' }}>
					{platforms.map((platform) => (
						<Select.Option key={platform.id} value={platform.id}>
							{platform.name}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				name={[fieldName, 'formats']}
				noStyle
				initialValue={initialValue?.formats?.map((f: any) => f.id)}
				rules={[
					{
						required: true,
						message: 'Please select a valid format',
					},
				]}>
				<Select
					placeholder="Formats"
					mode="multiple"
					loading={loading}
					optionFilterProp="children"
					filterOption={(input, option) =>
						(option!.children as unknown as string)
							.toLowerCase()
							.includes(input.toLowerCase())
					}
					showSearch
					style={{ width: '49%', marginLeft: '1%' }}
					value={formats}>
					{data?.formats.map((format) => (
						<Select.Option key={format.id} value={format.id}>
							{format.name}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
		</Input.Group>
	);
};

export default PlatformFormatsSelect;
